import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'
var pvhApiBaseCustorm = "http://localhost:5202/";

export const listInfo = (code, CustormName, CustormGroup, page, pageSize) => {
    return request({
        url: pvhApiBaseCustorm +"custorm/Custorm/GetCustormList",
        method: "get",
        params: {
            "code": code,
            "CustormName": CustormName,
            "CustormGroup": CustormGroup,
            "page": page,
            "pageSzie": pageSize
        }
    })
}
export const addInfo = (row) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/PostCustormInfo",
        method: "post",
        data: {
            ...row
        }
    })
}

//
export const editInfo = (row) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/PutCustormInfo",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const delInfo = (id) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/DeleteCustormInfo",
        method: "delete",
        params: {
                id
        }
    })
}
export const settlementMethodDDL = (type) => {
    return request({
        url: pvhApiBaseCustorm + "s/Supplier/GetSettlementMethodDic",
        method: "get",
        params: {
            type
        }
    })
}
export const getCreditLimitById = () => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/GetCreditLimitById",
        method: "get",
        params: {

        }
    })
}
export const codeIsExist = (code) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/GetCustormByCode",
        method: "get",
        params: {
            code
        }
    })
}

export const CustormList = () => {
    return request({
        url: pvhApiBaseCustorm +"custorm/Custorm/GetCustormInfoByCompany",
        method: "get"
    })
}